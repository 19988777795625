import { 
    GET_CURRENCY
} from './types'
import currencyService from "../service/currency.service"

export const getAllCurrency = () => (dispatch) => {
    return currencyService.getCurrency().then(res => {
        dispatch({
            type: GET_CURRENCY,
            payload: res.data.data
        })
    })
}