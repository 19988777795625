import baseAPI from "../../api/baseAPI"

const login = (username, password) => {
    return baseAPI
        .post("login", {
            username,
            password,
        })
        .then((response) => {
            return response.data
        })
}

const logout = () => {
    return baseAPI
        .post("logout")
        .then(response => {
            return response.data
        })
}

const verifyToken = () => {
    return baseAPI
        .get("verifyToken")
        .then(response => {
            return response.data
        })
}

export default {
    login,
    logout,
    verifyToken
}