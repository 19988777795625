import baseAPI from "../../api/baseAPI"

const getWarehouseLocations = () => {
    return baseAPI.get("warehouselocation")
}

const getWarehouseLocation = (id) => {
    return baseAPI.get(`warehouselocation/${id}`)
}

export default {
    getWarehouseLocations,
    getWarehouseLocation
}