import {
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    VERIFY_SUCCESS,
    VERIFY_FAIL
} from "./types"
import AuthService from "../service/auth.service"  
import UserService from "../service/user.service"
import { getAllStoreLocation } from "./storeLocation"  
// import { checkAuthSession } from "./session"
import { getAllWarehouseLocation } from "./warehouseLocation"
import { getAllCurrency } from "./currency"
import { getGeneral } from "./general"

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {

            dispatch(checkAuthSession())

            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data.data },
            })

            // dispatch(getAllWarehouseLocation())

        },
        (error) => {
            const message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()


            // console.log("Message: ", message)
            dispatch({
                type: LOGIN_FAIL,
                payload: { message: message },
            })

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            })

        }
    )
}

export const getUserProfile = () => (dispatch) => {
    return UserService.getUserProfile().then(
        (data) => {

            dispatch({
                type: LOAD_USER_SUCCESS,
                payload: { user: data.data.data.user },
            })
            // dispatch(getAllWarehouseLocation())

            return Promise.resolve()
        },
        (error) => {
            const message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()

            dispatch({
                type: LOAD_USER_FAIL,
                payload: { message: message },
            })

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            })

            return Promise.reject()
        }
    )
}

export const logout = () => (dispatch) => {
    AuthService.logout()

    dispatch({
        type: LOGOUT,
    })
}

export const checkAuthSession = () => (dispatch) => {
    return AuthService.verifyToken().then(
        (data) => {

            // console.log("Data: ", data)

            dispatch({
                type: VERIFY_SUCCESS,
                payload: { session: data },
            })

            if (data.isAuth) {
                dispatch(getUserProfile())
                dispatch(getAllWarehouseLocation())
                dispatch(getAllStoreLocation())
                dispatch(getAllCurrency())
                dispatch(getGeneral())
            }

            return Promise.resolve()
        },
        (error) => {
            const message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()

            dispatch({
                type: VERIFY_FAIL,
            })

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            })

            return Promise.reject()
        }
    )
}