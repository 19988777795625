import baseAPI from "../../api/baseAPI"

const getStoreLocations = () => {
    return baseAPI.get("storelocation")
}

const getStoreLocation = (id) => {
    return baseAPI.get(`storelocation/${id}`)
}

export default {
    getStoreLocations,
    getStoreLocation
}