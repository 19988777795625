import {
    VERIFY_SUCCESS,
    VERIFY_FAIL,
} from "../action/types";
  
const initialState = {
    isLoggedIn: false,
    status: 'loading'
}
  
export const session = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case VERIFY_SUCCESS:
            return {
                ...state,
                isLoggedIn: payload.session.isAuth,
                status: 'success'
            }
        case VERIFY_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                status: 'success'
            }
        default:
            return state
    }
}