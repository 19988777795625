import React from 'react'
// import reactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import { Provider } from "react-redux"
import store from "./stores/store"
import { checkAuthSession } from "./stores/action/auth"

import "./assets/scss/style.scss"
import 'antd/dist/antd.css'
// import 'antd/dist/antd.less'
import 'react-perfect-scrollbar/dist/css/styles.css'

import App from './App'
import { getGeneral } from './stores/action/general'

store.dispatch(getGeneral())
store.dispatch(checkAuthSession())

if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
)

// reactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>, 
//     document.getElementById('root')
// )