import { 
    GET_GENERAL,
    CHANGE_GENERAL_SUCCESS,
    CHANGE_GENERAL_FAIL
} from '../action/types'

const initialState = {
    general: []
}

export const general = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case GET_GENERAL:
            return {
                general: payload
            }
        default:
            return state
    }

}