import { 
    GET_ALL_STORE_LOCATIONS_SUCCESS, 
    GET_STORE_LOCATION_SUCCESS 
} from '../action/types'

const initialState = {
    stoer: []
}

export const storeLocation = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case GET_ALL_STORE_LOCATIONS_SUCCESS:
            return {
                store: payload
            }
        default:
            return state
    }

}