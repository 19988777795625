import baseAPI from "../../api/baseAPI"

const getAllUser = () => {
    return baseAPI.get("user")
}

const getUserProfile = () => {
    return baseAPI.get("user/profile")
}

const getUser = (id) => {
    return baseAPI.get(`user/${id}`)
}

const changePwd = (id) => {
    return baseAPI.get(`user/changePassword/${id}`)
}

export default {
    getAllUser,
    getUserProfile,
    getUser,
    changePwd
}