import { combineReducers } from "redux"
import { auth } from "./auth"
import { message } from "./message"
import { session } from "./session"
import { locale } from "./locale"
import { warehouseLocation } from './warehouseLocation'
import { storeLocation } from "./storeLocation"
import { currency } from "./currency"
import { general } from './general'

export default combineReducers({
    auth,
    message,
    session,
    locale,
    warehouseLocation,
    storeLocation,
    currency,
    general
})