import { 
    GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS, 
    GET_WAREHOUSE_LOCATION_SUCCESS 
} from '../action/types'
import warehouseService from "../service/warehouseLocation.service"

export const getAllWarehouseLocation = () => (dispatch) => {
    return warehouseService.getWarehouseLocations().then(res => {
        // console.log("res: ", res.data)
        dispatch({
            type: GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS,
            payload: res.data.data
        })
    })
}