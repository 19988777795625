import axios from "axios"
import { apiUrl } from "../utils/constants/variables"

const baseAPI = axios.create({
    withCredentials: true,
    mode: 'cors',
    baseURL: `${apiUrl}`,
    headers: { "Content-Type": 'application/json' },
    params: { apikey: 'Backend' }
})

export default baseAPI