import { CHANGE_LOCALE } from "../action/types"

const initialState = {
    locale: localStorage.getItem('i18n') ? localStorage.getItem('i18n') : 'en-US'
}

export const locale = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: payload
            }

        default: return { ...state }
    }
}