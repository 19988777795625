export const VERIFY_SUCCESS = "VERIFY_SUCCESS"
export const VERIFY_FAIL = "VERIFY_FAIL"

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

export const CHANGE_LOCALE = "CHANGE_LOCALE"

export const SET_MESSAGE = "SET_MESSAGE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"

export const GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS = "GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS"
export const GET_WAREHOUSE_LOCATION_SUCCESS = "GET_WAREHOUSE_LOCATION_SUCCESS"

export const GET_ALL_STORE_LOCATIONS_SUCCESS = "GET_ALL_STORE_LOCATIONS_SUCCESS"
export const GET_STORE_LOCATION_SUCCESS = "GET_STORE_LOCATION_SUCCESS"

export const GET_CURRENCY = "GET_CURRENCY"

export const GET_GENERAL = "GET_GENERAL"
export const CHANGE_GENERAL_SUCCESS = "CHANGE_GENERAL_SUCCESS"
export const CHANGE_GENERAL_FAIL = "CHANGE_GENERAL_FAIL"