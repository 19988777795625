import { 
    GET_ALL_STORE_LOCATIONS_SUCCESS, 
    GET_STORE_LOCATION_SUCCESS 
} from './types'
import storeService from "../service/storeLocation.service"

export const getAllStoreLocation = () => (dispatch) => {
    return storeService.getStoreLocations().then(res => {
        // console.log("storeLocation: ", res.data.data)
        dispatch({
            type: GET_ALL_STORE_LOCATIONS_SUCCESS,
            payload: res.data.data
        })
    })
}