import { 
    GET_GENERAL
} from './types'
import generalService from "../service/general.service"

export const getGeneral = () => (dispatch) => {
    return generalService.getGeneral().then(res => {
        dispatch({
            type: GET_GENERAL,
            payload: res.data.data[0]
        })
    })
}