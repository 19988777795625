import baseAPI from "../../api/baseAPI"

const getGeneral = () => {
    return baseAPI.get("general")
}

const updateGeneral = (id, data) => {
    return baseAPI.get(`general/${id}`, data)
}

export default {
    getGeneral,
    updateGeneral
}