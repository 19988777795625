import React, { lazy, Suspense, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ProtectedRoute from './routes/ProtectedRoute'
import PublicRoute from './routes/PublicRoute'

import { I18nProvider, LOCALES } from './lang'
import { setWebPageFavicon } from './helpers/setWebPageFavicon'
import { apiUrl } from './utils/constants/variables'

const Login = lazy(() => import('./pages/auth/Login'))
const Dashboard = lazy(() => import('./pages/app/dashboard/Dashboard'))
const PageNotFound = lazy(() => import('./pages/error/Error'))
// const About = lazy(() => import('./About'))
const AppLayout = lazy(() => import('./pages/app'))

function App() {

    // const location = useLocation()
    const general = useSelector(state => state.general.general)
    const locale = useSelector(state => state.locale.locale)

    const loadingState = useSelector(state => state.auth.status)

    // setWebPageFavicon('https://yt3.googleusercontent.com/ytc/AGIKgqNi-UUOwjMqSpgFfpgnSiLNmqpuJ74p9m-Bb9qSBA=s900-c-k-c0x00ffffff-no-rj')
    setWebPageFavicon(`${apiUrl}${general?.logo}`)

    if (loadingState === 'loading') {
        return <span>Loading...</span>
    }

    // console.log("locale", location.pathname)

    return (
        <I18nProvider locale={locale}>
            <BrowserRouter>
                <Suspense fallback={<div className='centered loader'> Loading...</div>}>
                    <Routes>
                        <Route path='/multi-store/*' element={<ProtectedRoute><AppLayout /></ProtectedRoute>} />
                        <Route path='/' element={<Navigate to='/multi-store' />} />
                        <Route path='/multi-store' element={<Navigate to='/multi-store/dashboard' />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </I18nProvider>
    )
}

export default App;
