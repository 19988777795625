import {
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    VERIFY_SUCCESS,
    VERIFY_FAIL
} from "../action/types";
  
const initialState = {
    user: {
        _id: "",
        username: "",
        email: "",
        role: {
            permissions: []
        },
        phoneNumber: "",
        token: "",
        profileImage: "",
        authStatus: null,
    },
    isLoggedIn: false,
    status: 'loading',
    message: "",
}
  
export const auth = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                user: payload.user,
                isLoggedIn: true,
                status: 'success'
            }
        case LOAD_USER_FAIL:
            return {
                ...state,
                user: null,
                message: payload.message,
                isLoggedIn: false,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: payload.user,
                isLoggedIn: true,
                status: 'success'
            }
        case LOGIN_FAIL:
            return {
                ...state,
                // user: null,
                message: payload.message,
                isLoggedIn: false,
            }
        case LOGOUT:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            }
        case VERIFY_SUCCESS:
            return {
                ...state,
                isLoggedIn: payload.session.isAuth,
                status: 'success'
            }
        case VERIFY_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                status: 'success'
            }
        default:
            return state
    }
}