import { 
    GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS, 
    GET_WAREHOUSE_LOCATION_SUCCESS 
} from '../action/types'

const initialState = {
    warehouse: []
}

export const warehouseLocation = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case GET_ALL_WAREHOUSE_LOCATIONS_SUCCESS:
            return {
                warehouse: payload
            }
        default:
            return state
    }

}