import { 
    GET_CURRENCY
} from '../action/types'

const initialState = {
    currency: []
}

export const currency = (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {
        case GET_CURRENCY:
            return {
                currency: payload
            }
        default:
            return state
    }

}